import * as React from 'react';
import PjIcon from '../../../components/icons/svg-icon';
import * as technologiesStyle from './technologies-used.module.scss';

const technologyIcons = [{
    name: 'java',
    width: '2.2em',
    height: '2.2em'
}, {
    name: 'angular',
    width: '2em',
    height: '2em'
}, {
    name: 'javascript',
    width: '2.2em',
    height: '2.2em'
}, {
    name: 'typescript',
    width: '2em',
    height: '2em'
}, {
    name: 'react',
    width: '2em',
    height: '2em'
}, {
    name: 'spring',
    width: '2em',
    height: '2em'
}, {
    name: 'hibernate',
    width: '2em',
    height: '2em'
}, {
    name: 'flutter',
    width: '2em',
    height: '2em'
}];

const TechnologiesUsed = () => {
    return (
        <section className={technologiesStyle.technologies + ` p-4r font-s3r text-center`}>
            <h1 className="mb-2r">TECHNOLOGIES USED</h1>
            <div className="row w-80 p-1r flex-jsb-alc-wrap">
                {[...technologyIcons, ...technologyIcons].map((icon, index) => {
                    return <span key={icon?.name + index} className={technologiesStyle.technologies__icon}><PjIcon iconName={icon?.name} width={icon?.width} height={icon?.height}></PjIcon></span>;
                })}
            </div>
        </section>
    )
};

export default TechnologiesUsed;
